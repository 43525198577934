a {
  color: #75808C;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0.5rem;
  font-family: 'Kanit', sans-serif;
  font-weight: bold;
}

a:hover {
  opacity: 0.9;
  color: #E02B20;
}