@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Titillium+Web&display=swap');

body {
  background: #16171b;
  color: #F5EDED;
  margin: 0 auto;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 500;
}

h1, h2, h3, h4 {
  font-family: 'Open Sans', sans-serif;
}
